import { uuid } from '@src/utils/uuid';
import { toKebab } from '@st/utils-js';

function toStyleString(style) {
  return Object.keys(style).map((key) => `${toKebab(key)}: ${style[key].toString()}`).join(';');
}

function setLabelAttrs(node, extraAttrs) {
  const style = {
    width: '100%',
    maxWidth: 'calc((400 - 18 - 12) * 1px)',
    height: 'max-content',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'black',
    fontFamily: 'ApexNew-Book'
  };

  const attrs = {
    class: 'checkbox-label',
    style: toStyleString(style),
    contenteditable: !node.attrs.readonly,
    'data-type': 'checkbox_label'
  };

  if(node.attrs.readonly) attrs.readonly = node.attrs.readonly;

  Object.keys(extraAttrs).forEach((prop) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });

  return attrs;
}

function getLabelAttrs(dom, extraAttrs) {
  const result = {};
  Object.keys(extraAttrs).forEach((prop) => {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) result[prop] = value;
  });
  return result;
}

function setFieldAttrs(node, extraAttrs) {
  const style = {
    position: 'relative',
    width: '18px',
    height: '18px',
    outline: 'none',
    backgroundColor: 'transparent',
    border: '1.5px solid black',
    borderRadius: '2px',
    pointerEvents: 'none'
  };

  const attrs = {
    class: 'checkbox_input',
    style: toStyleString(style),
    'data-type': 'checkbox_input'
  };

  if(node.attrs.readonly) attrs.readonly = node.attrs.readonly;

  Object.keys(extraAttrs).forEach((prop) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });

  return attrs;
}

function getFieldAttrs(dom, extraAttrs) {
  const result = {};
  Object.keys(extraAttrs).forEach((prop) => {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) result[prop] = value;
  });
  return result;
}

function setWrapperAttrs(node, extraAttrs) {
  const attrs = {
    class: 'checkbox-wrapper tw-relative tw-flex tw-items-start tw-justify-start tw-gap-3',
    id: node.attrs.id
  };

  Object.keys(extraAttrs).forEach((prop) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });

  return attrs;
}

function getWrapperAttrs(dom, extraAttrs) {
  const result = {};
  Object.keys(extraAttrs).forEach((prop) => {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) result[prop] = value;
  });
  return result;
}

export default {
  checkbox_wrapper: {
    inline: false,
    group: 'block',
    content: '(checkbox_label+ | checkbox_input+)+',
    isolating: true,
    attrs: {
      id: { default: uuid() },
      readonly: { default: true },
      label: { default: '' },
      placeholder: { default: 'Beschreibungstext hinzufügen' }
    },
    toDOM: (node) => [
      'div',
      setWrapperAttrs(node, {}),
      0 
    ],

    parseDOM: [
      {
        tag: 'div',
        getAttrs: (dom) => getWrapperAttrs(dom, {})
      }
    ]
  },
  checkbox_input: {
    isolating: true,
    attrs: {
      id: { default: uuid() },
      readonly: true,
      placeholder: { default: 'Beschreibungstext hinzufügen' }
    },
    toDOM: (node) => [
      'div',
      setFieldAttrs(node, {})
    ],

    parseDOM: [
      {
        tag: 'div',
        getAttrs: (dom) => getFieldAttrs(dom, {})
      }
    ]
  },

  checkbox_label: {
    content: 'paragraph',
    isolating: true,
    group: 'block',
    attrs: {
      id: { default: uuid() },
      readonly: { default: true },
      placeholder: { default: 'Beschreibungstext hinzufügen' }
    },
    // DOM Repräsentation angepasst an deine Struktur
    toDOM: (node) => [
      'div',
      setLabelAttrs(node, {}),
      0
    ],
    parseDOM: [
      {
        tag: 'div.checkbox-label',
        getAttrs: (dom) => getLabelAttrs(dom, {})
      } 
    ]
  }
};
