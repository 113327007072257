import { post } from '../axios';

const REQUEST_MAPPING = '/pdf';

export default {
  /**
   * generate pdf from html and css
   *
   * @param {string} html
   * @param {string} css
   * @param {string} script
   * @param {object} margin
   * @returns {Promise<string>}
   */
  generatePdf(html, css) {
    const url = `${REQUEST_MAPPING}/generate`;
    const body = {
      html,
      css
    };
    return post(url, body);
  }
};
