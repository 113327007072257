import { Schema } from 'prosemirror-model';
import marks from './marks';

import {
  section,
  tableNodes,
  paragraph,
  formularblock,
  textContentPhrase,
  checkbox,
  checkbox_wrapper,
  textfield,
  textfield_wrapper,
  textContentReference
} from './nodes/index';

const MainSchema = new Schema({
  marks,
  nodes: {
    section,
    table: tableNodes.table,
    table_header: tableNodes.table_header,
    table_row: tableNodes.table_row,
    table_cell: tableNodes.table_cell,
    paragraph,
    formularblock,
    phrase: textContentPhrase,
    textfield,
    ...textfield_wrapper,
    reference: textContentReference,
    checkbox,
    ...checkbox_wrapper,
    text: {
      group: 'inline'
    }
  },
  topNode: 'section'
});

export default MainSchema;
