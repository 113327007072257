export default class ClientElementLayout {
  constructor(
    layout = 'einspaltigesLayout',
    ueberschriftSchriftgroesse = 24,
    textSchriftgroesse = 12,
    ueberschriftFontFamily = 'Apex New',
    textFontFamily = 'Apex New',
    abstandOben = 30,
    abstandUnten = 30,
    abstandRechts = 20,
    abstandLinks = 20,
    abstandAbsatz = 18,
    abstandVorUeberschrift = 0,
    abstandSpalten = 4,
    textIstFett = 0,
    textIstUnterstrichen = 0,
    textIstKursiv = 0,
    ueberschriftIstFett = 0,
    ueberschriftIstUnterstrichen = 0,
    ueberschriftIstKursiv = 0,
    textSchriftFarbe = '#000',
    ueberschriftSchriftFarbe = '#000'
  ) {
    this.layout = layout;
    this.ptHeading = ueberschriftSchriftgroesse;
    this.ptText = textSchriftgroesse;
    this.fontFamilyHeading = ueberschriftFontFamily;
    this.fontFamilyText = textFontFamily;
    this.padding = {
      top: abstandOben,
      bottom: abstandUnten,
      right: abstandRechts,
      left: abstandLinks
    };
    this.gap = abstandAbsatz;
    this.headingGap = abstandVorUeberschrift;
    this.columnGap = abstandSpalten;
    this.textIstFett = textIstFett;
    this.textIstUnterstrichen = textIstUnterstrichen;
    this.textIstKursiv = textIstKursiv;
    this.ueberschriftIstFett = ueberschriftIstFett;
    this.ueberschriftIstUnterstrichen = ueberschriftIstUnterstrichen;
    this.ueberschriftIstKursiv = ueberschriftIstKursiv;
    this.textSchriftFarbe = textSchriftFarbe;
    this.ueberschriftSchriftFarbe = ueberschriftSchriftFarbe;
  }
}
