
function generateFormularblock() {
  return [
    'div', {
      class: 'formularblock tw-grid tw-grid-cols-1 tw-gap-5'
    },
    0
  ];
}

export {
  generateFormularblock
};

export default {
  group: 'block',
  content: '(textfield_wrapper|checkbox_wrapper)+',
  attrs: {
    readonly: { default: true },
    id: { default: null }
  },
  toDOM: () => generateFormularblock(),

  parseDOM: [
    {
      tag: 'div'
    }
  ]
};
