import { uuid } from '@src/utils/uuid';
import { toKebab } from '@st/utils-js';

function toStyleString(style) {
  return Object.keys(style).map((key) => `${toKebab(key)}: ${style[key].toString()}`).join(';');
}

function setLabelAttrs(node, extraAttrs) {
  const style = {
    width: '100%',
    height: '24px',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'black',
    fontFamily: 'ApexNew-Book'
  };

  const attrs = {
    class: 'textfield-label',
    style: toStyleString(style),
    contenteditable: !node.attrs.readonly,
    'data-type': 'textfield_label'
  };

  if(node.attrs.readonly) attrs.readonly = node.attrs.readonly;

  Object.keys(extraAttrs).forEach((prop) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });

  return attrs;
}

function getLabelAttrs(dom, extraAttrs) {
  const result = {};
  Object.keys(extraAttrs).forEach((prop) => {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) result[prop] = value;
  });
  return result;
}

function setFieldAttrs(node, extraAttrs) {
  const style = {
    width: '100%',
    maxWidth: '400px',
    height: '36px',
    outline: 'none',
    backgroundColor: 'var(--bbh-light-blue-40)',
    pointerEvents: 'none'
  };

  const attrs = {
    class: 'textfield_input',
    style: toStyleString(style),
    contenteditable: !node.attrs.readonly,
    'data-type': 'textfield_input'
  };

  if(node.attrs.readonly) attrs.readonly = node.attrs.readonly;

  Object.keys(extraAttrs).forEach((prop) => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrs);
  });

  return attrs;
}

function getFieldAttrs(dom, extraAttrs) {
  const result = {};
  Object.keys(extraAttrs).forEach((prop) => {
    const getter = extraAttrs[prop].getFromDOM;
    const value = getter && getter(dom);
    if (value != null) result[prop] = value;
  });
  return result;
}

export default {
  textfield_wrapper: {
    inline: false,
    group: 'block',
    content: '(textfield_input+ | textfield_label+)+',
    isolating: true,
    attrs: {
      id: { default: uuid() },
      readonly: { default: true },
      label: { default: '' },
      placeholder: { default: 'Titel eingeben' }
    },
    toDOM: (node) => [
      'div',
      {
        class: 'textfield-wrapper tw-relative',
        id: node.attrs.id
      },
      0 
    ],

    parseDOM: [
      {
        tag: 'div'
      }
    ]
  },
  textfield_input: {
    isolating: true,
    attrs: {
      id: { default: uuid() },
      readonly: true,
      placeholder: { default: 'Titel eingeben' }
    },
    toDOM: (node) => [
      'div',
      {
        ...setFieldAttrs(node, {})
      }
    ],

    parseDOM: [
      {
        tag: 'div',
        getAttrs: (dom) => getFieldAttrs(dom, {})
      }
    ]
  },

  textfield_label: {
    content: 'paragraph',
    isolating: true,
    inline: false,
    group: 'block',
    attrs: {
      id: { default: uuid() },
      readonly: { default: true },
      placeholder: { default: 'Titel eingeben' }
    },
    // DOM Repräsentation angepasst an deine Struktur
    toDOM: (node) => [
      'div',
      {
        ...setLabelAttrs(node, {})
      },
      0
    ],
    parseDOM: [
      {
        tag: 'div.textfield-label',
        getAttrs: (dom) => getLabelAttrs(dom, {})
      } 
    ]
  }
};
