export default {
  clientElement: {},
  clientElements: [],
  currentClientElementId: '',

  mandantCompareElement: {},

  clientElementZwangsupdate: {},
  updateIsFinishedCounter: 0,
  revisionsArray: [],

  liveView: false,
  liveViewRendered: false,
  liveViewDocLayout: {
    layout: 'einspaltigesLayout',

    ptHeading: 24,
    ptText: 12,
    fontFamilyHeading: 'Apex New',
    fontFamilyText: 'Apex New',

    padding: {
      top: 30,
      bottom: 30,
      right: 20,
      left: 20
    },

    gap: 18,
    headingGap: 0,
    columnGap: 4,

    textIstFett: 0,
    textIstUnterstrichen: 0,
    textIstKursiv: 0,
    ueberschriftIstFett: 0,
    ueberschriftIstUnterstrichen: 0,
    ueberschriftIstKursiv: 0,

    textSchriftFarbe: '#000',
    ueberschriftSchriftFarbe: '#000'
  },
  liveViewSectionLayouts: [],
  liveViewSectionLayoutsChangedCounter: 0,

  aenderungsmodus: false,
  aenderungsmodusHistory: {
    order: [],
    sections: {}
  },
  aenderungsmodusHistoryUpdateCounter: 0,
  aenderungsmodusOriginClientElement: {},
  aenderungsmodusSelectedBase: {},
  aenderungsmodusSelectedHead: {}
};

export const component = 'mandantEditor/';
